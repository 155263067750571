import type { ReactNode } from 'react';
import config from 'config';
import type { CmsContentDictionary } from '$cms/index';
import { getShortTextByKey, renderRichTextByKey } from '$cms/getDataByKey';
import { Link } from '$components/basic/Link';

export const getCmsData = (cmsData: CmsContentDictionary) => {
  const getShortText = getShortTextByKey.bind(cmsData);
  const getRichText = renderRichTextByKey.bind(cmsData);

  const customerServicePhone = getShortText(
    'customerService.contact.phone',
    config.get('public.phone_number')
  );

  const customerServiceEmail = getShortText(
    'customerService.contact.email',
    config.get('public.email.support')
  );

  const articles = {
    faqHowDoesFixterWorkHeading: getShortText('faq.howDoesFixterWork.heading', 'How does Fixter work?'),
    faqHowDoesFixterWorkContent: getRichText(
      'faq.howDoesFixterWork.content',
      <>
        Fixter is the complete solution for your car maintenance: we <b>pick your car</b> up, our in-house
        mechanics select the <b>best garage</b> and finally, we <b>return your car</b>.
        <br />
        <br />
        You just have to select an MOT, a car service or one of our +35 repairs available, and then choose{' '}
        collection &amp; delivery slots. You&apos;ll be able to book easily online and keep in mind that{' '}
        <b>we won&apos;t charge you</b> until all work is completed!
      </>
    ),

    faqWhoIsGoingToDriveMyCarHeading: getShortText(
      'faq.whoIsGoingToDriveMyCar.heading',
      'Who is going to drive my car?'
    ),
    faqWhoIsGoingToDriveMyCarContent: getRichText(
      'faq.whoIsGoingToDriveMyCar.content',
      <>
        All of our drivers are fully insured and your vehicle is always protected. We put our Fixter drivers
        through rigorous vetting and training before they are able to drive your vehicle. All of our drivers
        must have at least 3 years of driving experience, hold a clean driver&apos;s licence, be over the age
        of 21, capable of driving both manual and automatic vehicles and pass a criminal background check.
        Your vehicle will be assigned to a driver who is responsible for collecting it from your home,
        delivering it to the garage and returning your vehicle once all work is completed.
        <aside>
          💡 Sometimes we might coordinate with a garage for them to send their own driver to collect your
          vehicle. In this instance, you are protected as you would be if it was a Fixter driver.
        </aside>
      </>
    ),

    faqWhatGarageWillMyCarGoToHeading: getShortText(
      'faq.whatGarageWillMyCarGoTo.heading',
      'What garage will my car go to?'
    ),
    faqWhatGarageWillMyCarGoToContent: getRichText(
      'faq.whatGarageWillMyCarGoTo.content',
      <>
        One of our in-house mechanics will factor in the make and model of your car, as well as the work that
        needs doing. They will then allocate your booking to the most appropriate Fixter garage in your area.
        We work with the top 5% of independent garages in each area. Here are some of the reasons why only 1
        in 20 servicing centres qualify as a Fixter garage:
        <ul>
          <li>
            We only choose servicing centres registered as a Limited Company with a minimum of 3 years of
            trading.
          </li>
          <li>
            We assess each garage&apos;s various established affiliations (e.g. Bosch / Unipart / Garage).
          </li>
          <li>
            We perform an online audit, Fixter only selects garages with at least a 4/5 star customer rating.
          </li>
          <li>
            We make sure to visit each garage in person. Our in-house mechanics evaluate the quality
            equipment, staff qualifications, capacity, and technical capabilities of selected servicing
            centres. Fixter only works with facilities which strictly use manufacturer-equivalent parts.
          </li>
          <li>
            After all requirements are fulfilled, we negotiate preferential terms with each selected
            (including parts, labour, oil, and MOT test rates), so we can pass it along to our customers.
          </li>
        </ul>
      </>
    ),

    faqWhatPartsWillBeUsedHeading: getShortText(
      'faq.whatPartsWillBeUsed.heading',
      'What parts will be used?'
    ),
    faqWhatPartsWillBeUsedContent: getRichText(
      'faq.whatPartsWillBeUsed.content',
      <>
        All parts used in repairs and services carried out by Fixter are either manufacturer parts or
        equivalent. This guarantees that only the best quality parts are used in your vehicle. We offer a
        12-month warranty, meaning that should anything should go wrong with the parts or labour we’ll take
        care of it, free of charge.
      </>
    ),

    faqHowAndWhenDoIPayHeading: getShortText('faq.howAndWhenDoIPay.heading', 'How and when do I pay?'),
    faqHowAndWhenDoIPayContent: getRichText(
      'faq.howAndWhenDoIPay.content',
      <>
        We take your card details when you book online, but nothing is charged to your card until all the work
        has been approved and completed.
        <br />
        If you need to update your payment details to pay for your booking, the team will send you a secure
        link to add the new card details.
        <br />
        No extra work will be carried out without your consent. If additional work is required, one of our
        expert advisors will call you to walk you through any diagnostic reports, recommendations or
        additional quotes from the mechanic working on your car. We check every quote to make sure the work is
        required and has been fairly priced.
      </>
    ),

    faqHowDoesTheMoneyBackGuaranteeWorkHeading: getShortText(
      'faq.howDoesTheMoneyBackGuaranteeWork.heading',
      'How does the money back guarantee work?'
    ),
    faqHowDoesTheMoneyBackGuaranteeWorkContent: getRichText(
      'faq.howDoesTheMoneyBackGuaranteeWork.content',
      <>
        If your car fails its MOT at one of our garages, but you believe that your car should have passed the
        MOT, you&apos;ll have the option to take your car to another garage to do a new MOT test. If your
        vehicle passes the MOT at another garage, we&apos;ll refund you for the original MOT test that you
        carried out with us. Collection and delivery fees are not refundable.
      </>
    ),

    faqDoesFixterHave12MonthWarrantyHeading: getShortText(
      'faq.doesFixterHave12MonthWarranty.heading',
      'How does the Fixter 12-month warranty work?'
    ),
    faqDoesFixterHave12MonthWarrantyContent: getRichText(
      'faq.doesFixterHave12MonthWarranty.content',
      <>
        All Fixter bookings are covered by a 12-month warranty on parts and labour. If there&apos;s a problem
        with either the parts or labour up to 12 months after your booking date, we&apos;ll sort it out for
        you, completely free of charge. Just email{' '}
        <Link href={`mailto:${customerServiceEmail}`}>{customerServiceEmail}</Link> or call our team on{' '}
        <Link href={`tel:${customerServicePhone}`}>{customerServicePhone}</Link> and we’ll take care of it.
        Please note this doesn&apos;t apply to an air con regas as leaks can occur at any time.
      </>,
      {
        customerServiceEmail,
        customerServicePhone,
      }
    ),

    faqCanICancelMyBookingHeading: getShortText(
      'faq.canICancelMyBooking.heading',
      'Can I cancel my booking?'
    ),
    faqCanICancelMyBookingContent: getRichText(
      'faq.canICancelMyBooking.content',
      <>
        We understand that plans or circumstances can change between making the booking and the booking date.
        If you do for any reason need to cancel your booking,{' '}
        <b>
          please <Link href="/contact-us">let us know</Link> at least 24 hours before your booking date.
        </b>{' '}
        Cancellation is free when you give at least 24 hours notice. If less than 24 hours notice is given, we
        charge a cancellation fee of £20. Our team works <b>Monday to Friday</b> from{' '}
        <b>8:30 am to 6:30 pm.</b> If you cancel in the evening or over the weekend, we will not be able to
        process your request until the next weekday morning.
      </>
    ),
  };

  const faqArticleList: [string, ReactNode][] = [
    [articles.faqHowDoesFixterWorkHeading, articles.faqHowDoesFixterWorkContent],
    [articles.faqWhoIsGoingToDriveMyCarHeading, articles.faqWhoIsGoingToDriveMyCarContent],
    [articles.faqWhatGarageWillMyCarGoToHeading, articles.faqWhatGarageWillMyCarGoToContent],
    [articles.faqWhatPartsWillBeUsedHeading, articles.faqWhatPartsWillBeUsedContent],
    [articles.faqHowAndWhenDoIPayHeading, articles.faqHowAndWhenDoIPayContent],
    [
      articles.faqHowDoesTheMoneyBackGuaranteeWorkHeading,
      articles.faqHowDoesTheMoneyBackGuaranteeWorkContent,
    ],
    [articles.faqDoesFixterHave12MonthWarrantyHeading, articles.faqDoesFixterHave12MonthWarrantyContent],
    [articles.faqCanICancelMyBookingHeading, articles.faqCanICancelMyBookingContent],
  ];

  return {
    needHelpButtonLabel: getShortText('navbar.link.cta.needHelp', 'Need help?'),
    faqHeading: getShortText('needHelpModal.faqHeading', 'Frequently asked questions'),
    faqArticleList,
  };
};
