import type { Context } from '@apollo/client';
import type { IdentityProfile } from '$apollo/gql/identity';
import { useCheckIdentity } from '$apollo/gql/identity/hooks';

export interface IIdentityProvider {
  identityId?: string;
  identityProfile?: IdentityProfile;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
}

export interface IProps {
  context?: Context;
  children: (props: IIdentityProvider) => JSX.Element;
}

const IdentityProvider = ({ children }: IProps) => {
  const { data: identityCheck, loading, error } = useCheckIdentity();
  const identityProfile = identityCheck?.identityProfile;
  const identityProvider = {
    ...identityCheck,
    isAuthenticated: !error && identityCheck.isAuthenticated,
    isAuthenticating: loading,
    identityId: identityProfile?.identityId,
  };
  return children(identityProvider);
};

export default IdentityProvider;
