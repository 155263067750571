import styled from 'styled-components';
import { media } from '$util/theme';

export default styled.span`
  display: block;

  ${media.down('lg')} {
    width: 100%;
  }

  .drop-down-menu {
    position: relative;
    > button {
      display: flex;
      align-items: center;
      z-index: 1;
      position: relative;
      .arrow {
        width: 24px;

        .Image {
          vertical-align: middle;
        }
        img {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        &.-up img {
          transform: rotate(180deg);
        }
      }
    }
    > .content {
      z-index: 999;
      ${media.up('lg')} {
        position: fixed;
        right: 200px;
      }
      ${media.up('xl')} {
        margin-top: 10px;
      }
      ${media.up('xxl')} {
        right: auto;
      }
      > ul {
        margin-bottom: 0;
      }
    }
  }
`;
