import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface BoldLinkProps {
  url?: string;
  children?: ReactNode;
  className?: string;
}

function BoldLink({ url = '', children = '', className = '' }: BoldLinkProps): React.ReactNode {
  return (
    <a href={url} className={classNames(styles.boldLink, className)}>
      {children}
    </a>
  );
}

export default BoldLink;
