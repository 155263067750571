import styled from 'styled-components';
import { media, vars } from '$util/theme';

export default styled.main`
  background: ${vars.quantumverse.reskinColors.purple100};
  min-height: 100vh;

  ${media.down('sm')} {
    /**
    * padding required for bottom navigation sticky bar
    * without this you can't scroll to the end of the page
    */
    padding-bottom: 100px;
    .checkout-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .checkout-content {
    margin-top: -70px;
    ${media.up('md')} {
      margin-top: -110px;
    }
    ${media.up('lg')} {
      margin-top: -135px;
    }
    ${media.up('xl')} {
      margin-top: -150px;
    }
  }
`;
