import { gql } from '@apollo/client';
import {
  FRAGMENT_IMAGE_FIELD,
  FRAGMENT_SHORT_TEXT_FIELD,
  FRAGMENT_LINK_FIELD,
} from '$apollo/gql/cms/contentful';

export interface IQueryGetCurrentBookingForTrackingPageData {
  readonly getCurrentBookingForTrackingPage: string;
}
export const getNavbarQuery = gql`
  query getNavbarQuery($preview: Boolean, $locale: String!) {
    getNavbarQuery: component(id: "5mWMZIvTzZZ268bdhsJ0fa", locale: $locale, preview: $preview) {
      entriesCollection {
        items {
          ...ShortTextField
          ...ImgField
          ...LinkField
        }
      }
      # add the fields you want to query
    }

    # Hack to avoid adding this query to each individual page
    # Used on the navbar on all pages
    getCallUsBubbleQuery: component(id: "617xuFgWN355Gh6Q4JK7ox", locale: $locale, preview: $preview) {
      entriesCollection {
        items {
          ...ShortTextField
          ...ImgField
        }
      }
    }
  }

  ${FRAGMENT_IMAGE_FIELD}
  ${FRAGMENT_SHORT_TEXT_FIELD}
  ${FRAGMENT_LINK_FIELD}
`;

export const QUERY_GET_CURRENT_BOOKING_FOR_TRACKING_PAGE = gql`
  query getCurrentBookingForTrackingPage {
    getCurrentBookingForTrackingPage
  }
`;
