import { useQuery } from '@apollo/client';
import type { IQueryGetCurrentBookingForTrackingPageData } from '$commons/gql';
import { QUERY_GET_CURRENT_BOOKING_FOR_TRACKING_PAGE } from '$commons/gql';

/**
 * Hook to get the current booking of the customer
 * only returns the booking if it can be shown on the page
 */
export const useCurrentBookingForTrackingPageDropdownNavigation = () => {
  const { data, ...rest } = useQuery<IQueryGetCurrentBookingForTrackingPageData>(
    QUERY_GET_CURRENT_BOOKING_FOR_TRACKING_PAGE,
    {
      ssr: false,
    }
  );
  return { data: data?.getCurrentBookingForTrackingPage, ...rest };
};
