import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import LoggedInLinks from '$layout/partials/Header/components/LoggedInLinks';
import type { IdentityCheck, IdentityProfile } from '$apollo/gql/identity';
import IdentityProvider from '$providers/IdentityProvider';
import BoldLink from '$components/basic/BoldLink';
import { getLinkByKey } from '$cms/getDataByKey';
import { useAppContext } from '$util/AppContext';
import type { CmsContentDictionary, CmsContentProps } from '$cms/index';
import type { AppContextGlobal } from '$util/AppContextGlobal';
import { RETURN_TO_ACTIVE_PATH_SEGMENTS, RETURN_TO_PARAM_NAME } from '$constants/loginReturnTo';
import { DropDown } from '$src-components/atoms/DropDown';
import Styled from './styled';

const getLoginUrl = (thisOrigin: string, pathname: string, search: string, loginLink: string) => {
  const urlParts = [loginLink];

  if (RETURN_TO_ACTIVE_PATH_SEGMENTS.some((redirect) => pathname.startsWith(redirect))) {
    urlParts.push(`?${RETURN_TO_PARAM_NAME}=${encodeURIComponent(thisOrigin + pathname + search)}`);
  }

  return urlParts.join('');
};

const getDisplayName = (identityProfile: IdentityProfile) => {
  let display = 'Welcome';
  if (get(identityProfile, 'firstName', '')) {
    display = get(identityProfile, 'firstName', '');
  } else if (get(identityProfile, 'email')) {
    const [email] = get(identityProfile, 'email', '').split('@');
    return email || display;
  }
  return display;
};

function LoggedOutUser({
  pathname = '',
  search = '',
  loginLink = '/login',
  loginLabel = 'Login',
  loginIcon,
}: {
  pathname?: string;
  search?: string;
  loginLink?: string;
  loginLabel?: string;
  loginIcon: ReactNode;
}) {
  const {
    location: { origin },
  } = useAppContext<AppContextGlobal>();

  return (
    <>
      <BoldLink url={getLoginUrl(origin, pathname, search, loginLink)} className="login-btn">
        {loginLabel}
      </BoldLink>
      {loginIcon}
    </>
  );
}

function LoggedInUser({
  identityProfile,
  dropdownClassNames,
}: {
  identityProfile: IdentityProfile;
  dropdownClassNames?: string;
}) {
  return (
    <DropDown className={dropdownClassNames} title={getDisplayName(identityProfile)}>
      <ul>
        <LoggedInLinks />
      </ul>
    </DropDown>
  );
}

export interface ICurrentUserProps {
  isAuthenticatedCustomer: IdentityCheck['isAuthenticatedCustomer'];
  identityProfile?: IdentityProfile;
  className?: string;
  dropdownClassNames?: string;
  loginIcon?: ReactNode;
}

const getCmsData = (cmsData: CmsContentDictionary) => {
  const getShortText = getLinkByKey.bind(cmsData);

  return {
    loginLink: getShortText('navbar.link.login', {
      text: 'Login',
      url: '/login',
    }),
  };
};

export function CurrentUser({
  identityProfile,
  isAuthenticatedCustomer,
  className,
  dropdownClassNames,
  loginIcon,
}: ICurrentUserProps): JSX.Element {
  const [pathname, setPathname] = useState('');
  const [search, setSearch] = useState('');
  const {
    cmsContent: { dictionary },
  } = useAppContext<CmsContentProps>();

  useEffect(() => {
    if (process.browser) {
      setPathname(window.location.pathname);
      setSearch(window.location.search);
    }
  }, []);

  const { loginLink } = getCmsData(dictionary);

  return (
    <Styled className={classNames('CurrentUser', !isAuthenticatedCustomer && '-guest', className)}>
      {isAuthenticatedCustomer && identityProfile ? (
        <LoggedInUser identityProfile={identityProfile} dropdownClassNames={dropdownClassNames} />
      ) : (
        <LoggedOutUser
          pathname={pathname}
          search={search}
          loginLabel={loginLink.text}
          loginLink={loginLink.url}
          loginIcon={loginIcon}
        />
      )}
    </Styled>
  );
}

export function CurrentUserContainer({
  dropdownClassNames,
  loginIcon,
  className,
}: {
  readonly dropdownClassNames?: string;
  readonly loginIcon?: ReactNode;
  readonly className?: string;
}): JSX.Element {
  return (
    <IdentityProvider>
      {({ identityProfile, isAuthenticatedCustomer }: IdentityCheck) => {
        return (
          <CurrentUser
            identityProfile={identityProfile}
            isAuthenticatedCustomer={isAuthenticatedCustomer}
            className={className}
            dropdownClassNames={dropdownClassNames}
            loginIcon={loginIcon}
          />
        );
      }}
    </IdentityProvider>
  );
}
