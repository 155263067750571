import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useState, useCallback } from 'react';
import { Clickable } from '$components/basic/Clickable';
import { useAppContext } from '$util/AppContext';
import { useResizeViewport } from '$util/hooks/useResizeViewport';
import { internalAnalytics } from '$util/analytics/analyticsService/client';
import { googleAnalytics4 } from '$util/analytics/googleAnalytics4';
import Collapse from '@/components/molecules/Collapse';
import { Icon } from '@/components/atoms/Icon';
import { Button, DesignType } from '@/components/atoms/Button';
import TeamSupportSection from '../TeamSupportSection';
import { getCmsData } from './cms';
import Styled from './styled';

const analyticsNeedHelpClicked = () => {
  googleAnalytics4.needHelpClicked(null);
  internalAnalytics.sendAnalyticsNeedHelpClicked();
};

const analyticsNeedHelpFAQClicked = (heading: string): void => {
  internalAnalytics.sendAnalyticsNeedHelpFAQClicked(heading);
  googleAnalytics4.needHelpModalFAQClicked(heading);
};
export interface NeedHelpModalProps {
  readonly isMobile?: boolean;
  readonly showByDefault?: boolean;
  readonly children?: ReactNode;
  readonly buttonLabel?: string;
  readonly className?: string;
}

export function NeedHelpModal({
  isMobile,
  showByDefault = false,
  buttonLabel,
  className,
  children,
  ...props
}: NeedHelpModalProps): JSX.Element {
  const [showModal, setShowModal] = useState(showByDefault);
  const [buttonDesignType, setButtonDesignType] = useState(DesignType.SECONDARY_DARK);
  const {
    cmsContent: { dictionary },
  } = useAppContext();

  const setButtonType = useCallback((isMD: boolean) => {
    if (isMD) {
      setButtonDesignType(DesignType.SECONDARY_LIGHT);
    } else {
      setButtonDesignType(DesignType.SECONDARY_DARK);
    }
  }, []);

  useResizeViewport({
    breakpoint: 'md',
    direction: 'up',
    fn: setButtonType,
  });

  const toggleModal = () => {
    setShowModal((show) => !show);
  };
  const onNeedHelpButtonClick = () => {
    toggleModal();
    analyticsNeedHelpClicked();
  };

  const { needHelpButtonLabel, faqHeading, faqArticleList } = getCmsData(dictionary);

  return (
    <Styled className="NeedHelpModal NeedHelpModal" {...props}>
      <Button
        className={classNames('need-help', isMobile && '-mobile', className)}
        iconId="general/help-circle"
        designType={buttonDesignType}
        onClick={onNeedHelpButtonClick}
        size="S"
      >
        {buttonLabel || needHelpButtonLabel}
      </Button>
      {showModal && (
        <>
          <Clickable
            aria-label="modal overlay"
            className={`bg-overlay ${showModal && 'visible'}`}
            onClick={toggleModal}
          />
          <div className={classNames('need-help-modal', showModal && 'visible')}>
            <Icon
              role="button"
              className="close-button"
              id="general/x-close"
              legacy={false}
              onClick={toggleModal}
            />

            <div className="contact-container">
              <TeamSupportSection isModalContext source={NeedHelpModal.displayName} />
            </div>

            <div className="faq-container">
              <div className="heading">{faqHeading}</div>

              {faqArticleList.map(([headingValue, contentValue], i) => (
                <Collapse
                  key={headingValue}
                  title={<b>{headingValue}</b>}
                  expanded={i === 0}
                  onExpand={() => analyticsNeedHelpFAQClicked(headingValue)}
                  style={{ marginTop: 15, backgroundColor: '#fff' }}
                >
                  {contentValue}
                </Collapse>
              ))}
            </div>
          </div>
        </>
      )}
    </Styled>
  );
}

NeedHelpModal.displayName = 'NeedHelpModal';
