import type { HTMLAttributes } from 'react';
import React from 'react';
import { ConfigProvider } from 'antd';
import deferHydration from '$util/deferHydration';
import { useAppContext } from '$util/AppContext';
import type { AppContextGlobal } from '$util/AppContextGlobal';
import { CustomerCommonHead } from '$layout/partials/CustomerCommonHead';
import AdminWarning from '$components/AdminWarning';
import { QueuedModals } from '@/components/organisms/QueuedModals';
import { CustomerTopMegaNavigationBar } from '@/components/templates/CustomerTopMegaNavigationBar';
import MainStyled from './styled';

const LiveChat = deferHydration(() => import('$components/LiveChat'), {
  hydrationId: '$component/LiveChat',
  trigger: 'delay',
  delay: 5000,
});

interface CustomerLayoutAppContext extends AppContextGlobal {
  readonly pageTypeFromPath?: string;
  readonly initChat?: boolean;
}

export interface Props extends HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

export default function CustomerLayout({ children, ...props }: Props): JSX.Element {
  const { pageTypeFromPath, initChat } = useAppContext<CustomerLayoutAppContext>();

  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        hashed: false,
        token: { fontFamily: 'Rubik, sans-serif' },
      }}
    >
      <MainStyled {...props}>
        <CustomerCommonHead />
        <AdminWarning />
        <CustomerTopMegaNavigationBar />
        {children}
        <QueuedModals />
      </MainStyled>
      <LiveChat initChat={initChat} pageType={pageTypeFromPath} />
    </ConfigProvider>
  );
}
