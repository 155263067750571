import React, { type HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Locale } from '@fxtr/i18n';
import { NeedHelpModal } from '$quantum/organisms/NeedHelpModal';
import { CurrentUserContainer } from '$layout/partials/Header/components/CurrentUser';

import { MegaNav } from '@/components/organisms/MegaNav';
import { useAppContext } from '@/contexts/AppContext';
import { Icon } from '@/components/atoms/Icon';
import enTranslations from '@/components/organisms/MegaNav/translations/en.json';
import frTranslations from '@/components/organisms/MegaNav/translations/fr.json';
import styles from './index.module.scss';

export type CustomerTopMegaNavigationBarProps = HTMLAttributes<HTMLDivElement>;

/**
 * Templates have to be Server Components.
 * Might conditionally load components based on page context.
 */
export function CustomerTopMegaNavigationBar({
  className,
  ...props
}: CustomerTopMegaNavigationBarProps): JSX.Element {
  const { locale } = useAppContext();

  const translations = locale === Locale.EN_GB ? enTranslations : frTranslations;

  return (
    <div
      className={classNames(CustomerTopMegaNavigationBar.displayName, styles.component, className)}
      {...props}
    >
      <MegaNav
        showLoginLink={false}
        translations={translations}
        dynamicBlogData={[]}
        navigationMenuFooter={
          <CurrentUserContainer
            dropdownClassNames={styles.mobileDropdown}
            loginIcon={
              <div className={styles.loginIconContainer}>
                <Icon className={styles.icon} id="general/log-in-01" height={16} width={16} legacy={false} />
              </div>
            }
            className={classNames(styles['current-user_mobile'])}
          />
        }
      >
        <div className={classNames(styles['header-right'])}>
          <NeedHelpModal className={classNames('need-help-light', styles['need-help-cta'])} />
          <CurrentUserContainer className={classNames(styles['current-user_desktop'])} />
        </div>
      </MegaNav>
    </div>
  );
}
CustomerTopMegaNavigationBar.displayName = 'CustomerTopMegaNavigationBar';
