import styled from 'styled-components';
import { media, pxToRem, vars, cardShadowBig } from '$util/theme';

export default styled.div`
  .need-help-light {
    ${media.up('md')} {
      > div > div {
        background-color: ${vars.quantumverse.reskinColors.white};
        svg {
          color: ${vars.quantumverse.reskinColors.purple500};
        }
      }
    }
  }
  .need-help {
    ${media.up('lg')} {
      display: block;
    }
    &.-mobile {
      width: 100%;
      height: 56px;
      font-size: ${pxToRem(18)};
      display: block !important;
    }
  }

  .bg-overlay {
    background: rgba(0, 0, 0, 0.38);
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 1s;
    pointer-events: none;
    &.visible {
      pointer-events: all;
      opacity: 1;
    }
  }

  .need-help-modal {
    overflow-y: scroll;
    display: block;
    position: fixed;
    top: 0;
    z-index: 1000;
    background: #fbfdff;
    height: 100vh;
    box-shadow: -3px 0px 8px 2px rgba(0, 0, 0, 0.078);
    transition: 1s;
    width: 100vw;
    right: -110%;
    white-space: wrap;

    ${media.up('md')} {
      width: 450px;
      right: -100%;
    }

    &.visible {
      right: 0;
    }

    .close-button {
      font-weight: ${vars.fontWeight.regular};
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: ${pxToRem(38)};
    }

    .contact-container {
      padding: 30px 30px 15px 30px;
      background: ${vars.quantumverse.reskinColors.white};
    }

    .SectionTitle p {
      font-size: ${pxToRem(28)};
      font-weight: ${vars.fontWeight.semiBold};
      line-height: ${pxToRem(32)};
    }
    .TeamSupport__text p {
      margin-bottom: 0;
      p {
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(28)};
      }
    }

    .faq-container {
      padding: 30px;
      background: ${vars.quantumverse.reskinColors.neutral100};
      border-top: 1px solid #f3f0f0;
      overflow-y: auto;
      flex-grow: 1;
      padding-bottom: 100px;
      -webkit-overflow-scrolling: touch;

      .heading {
        font-size: ${pxToRem(26)};
        font-weight: ${vars.fontWeight.semiBold};
        line-height: ${pxToRem(32)};
        margin-bottom: 20px;
        text-align: center;
      }
      ul {
        padding-left: ${pxToRem(16)};
      }
      .ant-collapse {
        margin-top: 15px;
        background: ${vars.white};
        .ant-collapse-header-text {
          color: ${vars.quantumverse.reskinColors.neutral800};
          font-weight: ${vars.fontWeight.semiBold};
        }

        &.order-1 {
          box-shadow: ${cardShadowBig};
          border: 1px solid ${vars.transparent};
          &.collapse {
            border: 1px solid ${vars.quantumverse.reskinColors.brandLightGray};
            box-shadow: none;
          }
        }
      }
    }
  }
`;
