import { Link } from '$components/basic/Link';
import type { CmsContentDictionary } from '$cms/index';
import { getLinkByKey, getShortTextByKey } from '$cms/getDataByKey';
import { useAppContext } from '$util/AppContext';
import type { AppContextGlobal } from '$util/AppContextGlobal';
import { useCurrentBookingForTrackingPageDropdownNavigation } from '$commons/hooks/useCurrentBooking';
import type { CmsContentfulLink } from '$cms/contentful.types';
import { Icon } from '@/components/atoms/Icon';
import { StyledDiv } from './styled';

interface MenuItem extends Omit<CmsContentfulLink, '__typename'> {
  imageName: string;
  imageAlt: string;
}

const getCmsData = (cmsData: CmsContentDictionary): MenuItem[] => {
  const getLink = getLinkByKey.bind(cmsData);

  const logoutUrl = '/logout';

  const links = [
    {
      ...getLink('navbar.link.myBookings', {
        text: 'My bookings',
        url: '/customer/bookings',
      }),
      imageName: 'calendar',
      imageAlt: 'calendar',
    },
    {
      ...getLink('navbar.link.myAccount', {
        text: 'My account',
        url: '/customer/account',
      }),
      imageName: 'profile',
      imageAlt: 'profile',
    },
    {
      ...getLink('navbar.link.myCars', {
        text: 'My cars',
        url: '/customer/cars',
      }),
      imageName: 'car',
      imageAlt: 'car',
    },
    {
      ...getLink('navbar.link.logout', {
        text: 'Log out',
        url: logoutUrl,
      }),
      imageName: 'logout',
      imageAlt: 'logout',
    },
  ];

  return links;
};

function LoggedInLinks(): JSX.Element {
  const {
    cmsContent: { dictionary },
  } = useAppContext<AppContextGlobal>();

  const { data: bookingIdForTrackingPage } = useCurrentBookingForTrackingPageDropdownNavigation();

  const links = getCmsData(dictionary);
  const getShortText = getShortTextByKey.bind(dictionary);

  if (bookingIdForTrackingPage)
    links.unshift({
      key: 'TrackCurrentBooking', // Not a Contentful key
      text: getShortText('navbar.link.trackMyBooking.text', 'Track my current booking'),
      url: `/customer/bookings/${bookingIdForTrackingPage}/tracking?source=dropdownMenu`,
      imageName: 'magnifineLines',
      imageAlt: 'trackMyBooking',
    });
  return (
    <>
      {links.map((link) => (
        <li key={link.key}>
          <Link type={3} href={link.url}>
            <StyledDiv className="optionContainer">
              <Icon id={link.imageName} className="optionIcon" height={16} />
              <span className="optionText">{link.text}</span>
            </StyledDiv>
          </Link>
        </li>
      ))}
    </>
  );
}

export default LoggedInLinks;
